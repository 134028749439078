(function ($, Drupal) {
  Drupal.behaviors.categoryImageGridV1 = {
    attach: function (context) {
      var $module = $('.js-category-grid--v1', context);

      function updateResponsive() {
        var newBreakpoint = Unison.fetch.now().name;
        var isMobileWidth = newBreakpoint !== 'landscape';

        if ($module.hasClass('slick-initialized')) {
          $module.slick('unslick');
        }
        if (isMobileWidth) {
          $module.slick({
            slidesToShow: 1,
            variableWidth: true
          });
        }
      }
      updateResponsive();

      MantleWindow.onResize(function () {
        // Refresh
        updateResponsive();
      }, 'categoryIamgeGrid');
    }
  };
})(jQuery, Drupal);
